import {
  makeStyles
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {ErrorMessage, Field, useFormikContext} from "formik";
import {Button} from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import {getConsultationDate, insuranceCompanyDetails} from "../api";
import {toast} from "react-toastify";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import "./InsurerForm.scss";

const insurerFields = [
  {
    name: "postal_code",
    label: "Postal Code",
    type: "text",
    placeholder: "Enter your Postal Code",
  },
  {
    name: "address",
    label: "Address",
    type: "text",
    placeholder: "Enter your  Address",
  },
  {
    name: "authorization_no",
    label: "Authorization Number",
    type: "text",
    placeholder: "Enter your authorization number",
  },
  {
    name: "membership_no",
    label: "Membership Number",
    type: "text",
    placeholder: "Enter your membership Number",
  },
];

const useStyles = makeStyles(theme => ({
  loading: {textAlign: "center"},
  checkbox: {marginRight: 10},
  infoText: {color: "black", fontSize:"10px", fontWeight:"italic"},
  checkLabel: {color: theme.palette.primary.main},
  second: {margin: "0 4.5%"},
  padding: {padding: "5% 0 0 10%"},
  label: {marginBottom: "10px"},

  form: {
    backgroundColor: "white",
    borderRadius: "5px",
  },
  align: {
    paddingLeft: "5%",
  },
  gap: {
    marginTop: 20,
  },
  paper: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4%",
    },
  },
  disableDate: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    cursor: "not-allowed",
  },
}));

export const InsurerForm = () => {
  const captchaRef = useRef(null);
  const [availableDateTimeSchema, setAvailableDateTimeSchema] = useState(null);
  const [availableTimes, setAvailableTimes] = useState(null);
  const [companyDetails, setCompanyDetails] = useState();

  const [companyId, setCompanyId] = useState("");
  const formik = useFormikContext();

  const handleSelectChange = event => {
    setCompanyId(event.target.value);
    formik.setFieldValue("insurance_company_id", event.target.value);
  };
  
  const SITE_KEY = process.env.REACT_APP_LIVE_CAPTCHA_SITE_KEY;

  useEffect(() => {
    insuranceCompanyDetails().then(res => {
      setCompanyDetails(res);
    });
  }, []);

  useEffect(() => {
    getConsultationDate()
      .then(arr => {
        setAvailableDateTimeSchema(arr);
      })
      .catch(err => {
        toast.error(
          "An error occurred while trying to fetch the available dates"
        );
      });
  }, []);

  useEffect(() => {
    if (formik.values.consultDate && availableDateTimeSchema) {
      const index = availableDateTimeSchema.findIndex(item => {
        return item.date === formik.values.consultDate;
      });
      if (index === -1) {
        setAvailableTimes(null);
      } else {
        setAvailableTimes(availableDateTimeSchema[index]["time"]);
      }
    }
  }, [formik.values.consultDate, availableDateTimeSchema]);

  const handleChange = value => {
    formik.setFieldValue("recaptcha", value);
  };
  return (
    <div className="insurance-form-section">
      <div className="single-blog-section">
        <div className="wrapper" style={{padding: "40px"}}>
          <div className="container">
            <p style={{color: "#17664e", fontWeight: 600}}>
              <i>
                "You have been redirected to this web page considering your
                insured data had been omitted."
              </i>
            </p>

            <div className="row">
              <div className="col-md-6">
                <h6>Insurance Company</h6>
                <Select2
                  value={companyId}
                  name="insurance_company_id"
                  className="form-control"
                  data-placeholder="Select your Insurance Company"
                  data={companyDetails}
                  onChange={handleSelectChange}
                />

                <div style={{color: "red"}}>
                  <ErrorMessage name="insurance_company_id" />
                </div>
              </div>

              {insurerFields.map(({label, name, type, placeholder}) => {
                return (
                  <div className="col-md-6">
                    <div style={{marginBottom: "15px"}}>
                      <h6>{label}</h6>

                      <Field
                        className="form-control"
                        type={type}
                        name={name}
                        placeholder={placeholder}
                      />

                      <div style={{color: "red"}}>
                        <ErrorMessage name={name} />
                      </div>
                    </div>
                  </div>
                );
              })}

              <Field
                name="recaptcha"
                style={{marginTop: "10px", marginBottom: "10px"}}>
                {({field}) => {
                  return (
                    <>
                      <ReCAPTCHA
                        // sitekey={TEST_SITE_KEY}
                        sitekey={SITE_KEY}
                        ref={captchaRef}
                        size="normal"
                        onChange={handleChange}
                        // asyncScriptOnLoad={asyncScriptOnLoad}
                      />
                      <div style={{color: "red"}}>
                        <ErrorMessage name="recaptcha" />
                      </div>
                    </>
                  );
                }}
              </Field>
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                textTransform: "uppercase",
                fontWeight: "500",
                marginTop: "10px",
              }}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
