import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Button, useMediaQuery, useTheme} from "@material-ui/core";
// import logo2 from "../images/veclogo1.png";
import {useHistory} from "react-router-dom";
import DrawerComponent from "../components/DrawerComponent";
import {getVirtualeyeImage, DOMAIN} from "../api";
import {toast} from "react-toastify";
import {useQuery} from "react-query";
// import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles(theme => ({
  headernav: {
    position: "fixed",
    width: "100%",
    top: 0,
    zIndex: 112,
    background: "transparent",
  },

  navBtn: {
    "&:hover": {
      background: "rgb(16, 71, 54) !important",
      color: "#fff  !important",
    },
  },
  scrolledHead: {
    "position": "fixed",
    "top": 0,
    "left": 0,
    "backgroundColor": "#f5f5f7 ",
    // transition: "all .5s ease-in",
    // boxShadow: " 0 16px 5px -7px #abb7b652;",
    "boxShadow":
      "rgb(50 50 93 / 10%) 0px 13px 27px -5px, rgb(0 0 0 / 5%) 0px 8px 16px -8px;",
    "zIndex": 112,

    "& img": {
      // marginLeft: "40px",
    },
  },
  root: {
    flexGrow: 1,
    width: "90%",
    margin: "0 auto 10px",
  },
  logo: {
    marginTop: "20px",
    marginRight: "auto",
    justifySelf: "flex-start",
    padding: "5px 2px 11px 0px !important",
    marginLeft: "75px",

    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  logoimg: {
    cursor: "pointer",
  },
  NavItem: {
    marginTop: "11px",
    textAlign: "center",
    // marginLeft: "219px",
  },
  NavItems: {
    marginTop: "11px",
    textAlign: "center",
    zIndex: 10000,
    // marginLeft: "219px",
    marginRight: "85px",

    [theme.breakpoints.down("md")]: {
      marginRight: "0px ",
    },
  },

  items: {
    "padding": "8px 14px!important",

    "&:hover": {
      background: "none",
      color: "#17664E",
    },
    "fontFamily": "'Roboto', sans-serif",
    "fontSize": "16px",
    "fontWeight": "500",
    "textDecoration": "none",
    "textTransform": "uppercase",

    [theme.breakpoints.down("md")]: {
      padding: "6px !important",
      fontSize: "16px",
    },
  },
}));

export default function FullWidthGrid() {
  const {data} = useQuery("virtualeyeimage", getVirtualeyeImage, {
    onError: e => toast.error(e.message || "Error fetching data"),
  });
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const drawer = useMediaQuery(theme.breakpoints.down("sm"));
  const [scrolledHead, setScrolledHead] = React.useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 10) {
      setScrolledHead(true);
    } else {
      setScrolledHead(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <div>
      <div
        className={[
          classes.headernav,
          classes.navbar,
          scrolledHead ? classes.scrolledHead : undefined,
        ].join(" ")}>
        <div className={classes.root}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={4}
            style={{padding: "4px"}}>
            <Grid item className={classes.logo}>
              <div className={classes.logoimg}>
                <a style={{textDecoration: "none", color: "inherit"}} href="/">
                  <img
                    style={{width: "auto", height: "57px"}}
                    src={DOMAIN + data?.[0]?.logo?.url}
                    alt="logo"
                  />
                </a>
              </div>
            </Grid>

            {drawer ? (
              <DrawerComponent />
            ) : (
              <>
                <Grid
                  item
                  className={classes.NavItem}
                  style={{padding: "4px"}}>
                  <Button className={classes.items}>
                    <a
                      style={{textDecoration: "none", color: "inherit"}}
                      href="/">
                      Home
                    </a>
                  </Button>
                </Grid>
                <Grid
                  item
                  className={classes.NavItem}
                  style={{padding: "4px"}}>
                  <Button className={classes.items}>
                    {" "}
                    <a
                      style={{textDecoration: "none", color: "inherit"}}
                      href="/#about">
                      About Us
                    </a>{" "}
                  </Button>
                </Grid>
                <Grid
                  item
                  className={classes.NavItem}
                  style={{padding: "4px"}}>
                  <Button className={classes.items}>
                    {" "}
                    <a
                      style={{textDecoration: "none", color: "inherit"}}
                      href="/#price">
                      Our Plans
                    </a>{" "}
                  </Button>
                </Grid>
                <Grid
                  item
                  className={classes.NavItem}
                  style={{padding: "4px"}}>
                  <Button className={classes.items}>
                    {" "}
                    <div
                      onClick={() => history.push("/blogs")}
                      style={{textDecoration: "none", color: "inherit"}}>
                      Blog
                    </div>{" "}
                  </Button>
                </Grid>
                <Grid
                  item
                  className={classes.NavItem}
                  style={{padding: "4px"}}>
                  <Button className={classes.items}>
                    {" "}
                    <a
                      style={{textDecoration: "none", color: "inherit"}}
                      href="/#contact">
                      Contact Us
                    </a>
                  </Button>
                </Grid>

                <Grid
                  item
                  className={classes.NavItems}
                  style={{padding: "4px"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/consult")}
                    className={[classes.items, classes.navBtn].join(" ")}>
                    Consult Now
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}
