/**
 *
 * @returns {'localhost'|'uat' | 'qa' | 'live'}
 */
export const getCurrentHost = () => {
  const host = window.location.host;
  if (host.includes("localhost")) {
    return "localhost";
  }
  if (host.includes("uat.admin")) {
    return "uat";
  }
  if (host.includes("qa")) {
    return "qa";
  }
  return "live";
};
