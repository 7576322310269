import React from "react";
import {Grid, makeStyles} from "@material-ui/core";
import CheckboxSections from "./CheckboxSections";
import DescriptionAndFileSection from "./DescriptionAndFileSection";

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: "50px",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "10px",
    [theme.breakpoints.up("xs")]: {
      paddingRight: " 10px",
      paddingLeft: "10px",
    },
  },
}));
const checkboxconfig = [
  {
    question: "What type of symptoms do you have?",
    name: "symptoms",
    type: "checkbox",
    options: [
      {label: "Pain", value: "pain"},
      {label: "Itchy", value: "itchy", class: "symptoms"},
      {label: "Red Eye", value: "Red Eye"},
      {label: "Watery Eye", value: "Watery Eye"},
      {label: "Reduced Vision", value: "Reduced Vision"},
      {label: "Other", value: "Other"},
      {label: "No Symptoms", value: "No symptoms", nuke: true},
    ],
  },
  {
    question: "In which eye are you experiencing symptoms?",
    name: "eye",
    type: "radio",

    options: [
      {label: "Left", value: "Left"},
      {label: "Right", value: "Right"},
      {label: "Both", value: "Both"},
    ],
  },
  {
    question: "How long have you had the symptoms?",
    name: "duration",
    type: "radio",
    options: [
      {label: "1 Day", value: "1 Day"},
      {label: "Less than 1 week", value: "Less than 1 Week"},
      {label: "1-4 weeks", value: "1 week"},
      {label: "1-6 months", value: "1 month"},
      {label: " 6-12 months", value: "6 months"},
      {label: "1 year or more", value: "1 year/more"},
    ],
  },
];

const Main = () => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Grid container spacing={10} justifyContent="space-between">
        <Grid item xs={12} md={7} container spacing={0} style={{alignContent:"flex-start"}}>
          <CheckboxSections config={checkboxconfig} />
        </Grid>
        <Grid
          style={{
            backgroundColor: "#F8F8F8",
            marginBottom: "5%",
            marginTop: "2%",
          }}
          item
          xs={12}
          md={5}
          container>
          <DescriptionAndFileSection />
        </Grid>
      </Grid>
    </div>
  );
};

export default Main;
