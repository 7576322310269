import React, {useEffect, useState} from "react";
import Navbar from "./Navbar";
import {Form, Formik} from "formik";
import {ReScheduleForm} from "./ReScheduleForm";
import {updateRescheduleDate, verifyReschedule} from "../api";
import {Redirect, useHistory} from "react-router-dom/cjs/react-router-dom.min";

var url = new URL(window.location.href);
var path = url.pathname;
var segments = path.split("/");
var value = segments[segments.length - 1];
const slug = value;

export const Reschedule = () => {
  const history = useHistory();
  const [access, setAccess] = useState();

  useEffect(() => {
    verifyReschedule(slug).then(res => {
      setAccess(res.data);
    });
  }, [slug]);

  if (access === false) {
    return <Redirect to="/thankyou" />;
  } else {
    return (
      <div className="reschedule-section">
        <Navbar />
        <Formik
          initialValues={{
            name: "",
            email: "",
            dob: "",
            contact: "",
            preferVideoConsultation: false,
            scheduleObject: null,
            availableDateTimeSchema: [],
            consultDate: "",
          }}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            const doctorId = values.scheduleObject.id;
            updateRescheduleDate(slug, doctorId).then(res => {
              if (res.status === 200) {
                history.push("/thankyou");
              } else {
              }
              setSubmitting(false);
            });
          }}>
          {() => (
            <Form>
              <ReScheduleForm />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
};
