import {Grid, makeStyles} from "@material-ui/core";
import React from "react";
import {useHistory} from "react-router";
// import Popup from "./PopUp";
import {ReactComponent as FacebookIcon} from "../assets/facebook.svg";

import {ReactComponent as YoutubeIcon} from "../assets/youtube.svg";
const useStyles = makeStyles(themes => ({
  container: {
    position: "relative",
    zIndex: 10,
    background: "#17664E",
    color: "#FFFF",
    fontSize: "12px",
    fontWeight: "500",
    padding: "20px 80px",
  },
  iconsmedia: {
    width: "calc(100% + 16px)",
    padding: "3px",

    [themes.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  midSection: {
    [themes.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  
  midLink: {
    display: "flex",
    [themes.breakpoints.down("xs")]: {
      display: "unset"
    },
  },
  poweredSection: {
    [themes.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  midInnerLink: {
      display: "flex"
  }
}));
function Footer() {
  const classes = useStyles();
  const history = useHistory();
  // const [openPopUp, setOpenPopUp] = useState(false);
  return (
    <div>
      <Grid
        container
        className={classes.container}
        justifyContent="space-between"
        alignItems="center">
        <Grid className={classes.midSection} >
          <Grid
            item
            container
            direction="row"
            className={classes.iconsmedia}
            spacing={2}>
            <Grid item>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/LondonEyeConsultants/">
                <FacebookIcon />
              </a>
            </Grid>
            <Grid item>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCTpa5o754bb9F2mr3ZZTxcw">
                <YoutubeIcon />
              </a>
            </Grid>
            {/* <Grid item> */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {/* <a href="">
            <TwitterIcon />
          </a>*/}
            {/* </Grid> */}
          </Grid>
        </Grid>

        <Grid className={classes.midLink}>
          <Grid item style={{minWidth: 250, margin: "0 16px"}} xs={12}>
            <p style={{marginBottom: "0"}}>
              © 2021 Virtual Eye | All Rights Reserved
            </p>
          </Grid>
          <Grid className={classes.midInnerLink}>
            <Grid item style={{ margin: "0 16px"}} xs={6}>
              <p
                style={{cursor: "pointer", marginBottom: "0"}}
                onClick={() => history.push("/privacy")}>
                Privacy
              </p>
            </Grid>
            <Grid item style={{ margin: "0 16px"}} xs={6}>
              <p
                style={{cursor: "pointer", marginBottom: "0"}}
                onClick={() => history.push("/terms")}>
                Terms
              </p>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={classes.poweredSection}>
          <p style={{marginBottom: "0"}}>
            Powered by:{" "}
            <a
              rel="noreferrer"
              style={{color: "#fff", textDecoration: "none", fontWeight: "600"}}
              href="https://khwopatech.com/"
              target="_blank">
              KhowpaTech
            </a>
          </p>
        </Grid>
      </Grid>
      {/* <Popup openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} /> */}
    </div>
  );
}

export default Footer;
