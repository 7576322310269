import React, {useEffect} from "react";
import {Container, Grid} from "@material-ui/core";
import Navbar from "./Navbar";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import MetaConfigure from "../components/helmet";

import {makeStyles} from "@material-ui/styles";
const useStyles = makeStyles(theme => ({
  privacySection: {
    marginTop: "80px",
  },
  Privacy: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "",
    textAlign: "left",
    padding: "0px 30px 30px 30px",
  },
  title: {
    textAlign: "left",
    fontSize: "bold",
    lineHeight: "115%",
    color: "#5E5E5E",
  },
  list: {
    listStyle: "decimal",
  },
  listitem: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

const Privacy = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaConfigure title="Privacy - Virtual Eye Consultant - Eye health and Eye Care" />

      <Navbar />
      <div className={classes.privacySection}>
        <Container>
          {/* <Grid container direction="row" justifyContent="center"> */}

          <Grid item className={classes.Privacy}>
            {/* <h1 className={classes.title}>Privacy</h1> */}
            <h2 className={classes.title}> Privacy</h2>
            This statement explains the privacy and the data protection
            practices which apply to VirtualEye.net as provided by London Eye
            Consultant. Our aim is to safeguard our users’ privacy whilst
            providing a personalized and valuable service. Collecting personal
            information is necessary if we are to satisfy the expectations and
            requirements of our users, e.g. by communicating with them and
            providing an interactive service. We appreciate that you do not want
            the personal information you provide to us distributed
            indiscriminately and here we explain what information we collect,
            what we do with it and what controls you have.
          </Grid>

          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}> Security</h2>
            At Virtual Eye Consultant we are committed to protecting your
            privacy, we follow strict security procedures to ensure that your
            personal information is not damaged, destroyed, or disclosed to a
            third party without your permission, and to prevent unauthorized
            access. The computers that store the information are kept in a
            secure facility with restricted physical access, and we use secure
            firewalls and other measures to restrict electronic access.
          </Grid>

          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}> Personal Information</h2>
            Any personal information such as name, postal address, telephone
            number, and email address given via this website will only be used
            to provide a requested service and will not be disclosed to any
            other third party without your prior permission or unless we are
            required to do so by law.
          </Grid>

          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}> General Information</h2>
            Any non-personal information such as IP address, pages accessed, and
            files downloaded will only be used to determine how many individuals
            use our site, how many people visit on a regular basis, which pages
            are most popular, and which pages are least popular. This
            information doesn’t tell us anything about who you are or where you
            live, it is simply used to enable us to monitor and improve our
            service.
          </Grid>

          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}> Links</h2>
            This website may contain links to third party sites we have no
            control over, if you visit those sites you should check you are
            happy with their own privacy policies and terms of use before
            providing any personal information. In addition, if you are linked
            to our website from a third party, we cannot be responsible for the
            privacy policies and practices of that third party site.
          </Grid>

          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}>Your Acceptance</h2>
            By using this website, you consent to the collection and use of
            information by Virtual Eye Consultant in accordance with our privacy
            policy. If you do not agree to this policy, please do not use our
            site.
          </Grid>

          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}>Your Rights</h2>
            Any personal information submitted via our website is treated in
            accordance with the Data Protection Act 1998, registration number .
            To find out more about your entitlements under this legislation,
            visit the Information Commissioner’s web site at
            www.gov.uk/data-protection, or read the Act online
            atwww.hmso.gov.uk/acts/acts1998/19980029.htm. If you would like to
            review or revise information you have previously provided to us
            online, or you have any questions relating to this Privacy Policy,
            you may do so via our contact us page.
          </Grid>

          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}>Use of Cookies</h2>
            Our website uses cookies, as almost all websites do, to help provide
            you with the best experience we can. Cookies are small text files
            that are placed on your computer or mobile phone when you browse
            websites.
          </Grid>

          <Grid>
            {/* <p> Our cookies help us:</p> */}
            <ul className={classes.list}>
              Our cookies help us:
              <li>Make our website work as you’d expect</li>
              <li>Remember your settings during and between visits</li>
              <li>Improve the speed/security of the site</li>
              <li>Allow you to share pages with social networks</li>
              <li>Continuously improve our website for you</li>
              <li>
                Make our marketing more efficient (ultimately helping us to
                offer the service we do at the price we do)
              </li>
            </ul>
          </Grid>

          <Grid>
            <ul className={classes.list}>
              We do not use cookies to:
              <li className={classes.listitem}>
                {" "}
                Collect any personally identifiable information (without your
                express permission)
              </li>
              <li className={classes.listitem}>
                Collect any sensitive information (without your express
                permission)
              </li>
              <li className={classes.listitem}>
                Pass data to advertising networks
              </li>
              <li className={classes.listitem}>
                {" "}
                Pass personally identifiable data to third parties
              </li>
              <li className={classes.listitem}> Pay sales commissions</li>
              <li> You can learn more about all the cookies we use below.</li>
            </ul>
          </Grid>

          <Grid item className={classes.Privacy}>
            <h4>Granting us permission to use cookies</h4>

            <p>
              If the settings on your software that you are using to view this
              website (your browser) are adjusted to accept cookies we take
              this, and your continued use of our website, to mean that you are
              fine with this. Should you wish to remove or not use cookies from
              our site you can learn how to do this below, however doing so will
              likely mean that our site will not work as you would expect.
            </p>
          </Grid>

          <Grid item className={classes.Privacy}>
            <h4>More about our Cookies</h4>
            <ul className={classes.list}>
              We use cookies to make our website work including:
              <li>Remembering if you have accepted our terms and conditions</li>
              <li>Showing you which pages you have recently visited</li>
              <li>
                There is no way to prevent these cookies being set other than to
                not use our site.
              </li>
            </ul>
          </Grid>

          <Grid item className={classes.Privacy}>
            <h4>Social Website Cookies</h4>
            <p>
              So you can easily like or share our content on social media
              platforms we have included sharing buttons on our site.
            </p>
          </Grid>

          <Grid item className={classes.Privacy}>
            <h4>Cookies are set by:</h4>

            <p>
              The privacy implications on this will vary from social network to
              social network and will be dependent on the privacy settings you
              have chosen on these networks.
            </p>
          </Grid>

          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}> Visitor Statistics Cookies</h2>

            <p>
              {" "}
              We use cookies to compile visitor statistics such as how many
              people have visited our website, what type of technology they are
              using (e.g. Mac or Windows which helps to identify when our site
              isn’t working as it should for particular technologies), how long
              they spend on the site, what page they look at etc. This helps us
              to continuously improve our website. These so called analytics
              programs also tell us if how people reached this site (e.g. from a
              search engine) and whether they have been here before helping us
              to put more money into developing our services for you instead of
              marketing spend.
            </p>
          </Grid>
          <Grid item className={classes.Privacy}>
            <h2 className={classes.title}> Turning Cookies Off</h2>

            <p>
              {" "}
              You can usually switch cookies off by adjusting your browser
              settings to stop it from accepting cookies (Learn how here). Doing
              so however will likely limit the functionality of ours and a large
              proportion of the world’s websites as cookies are a standard part
              of most modern websites.
            </p>

            <p>
              {" "}
              It may be that you concerns around cookies relate to so called
              “spyware”. Rather than switching off cookies in your browser you
              may find that anti-spyware software achieves the same objective by
              automatically deleting cookies considered to be invasive. Learn
              more about managing cookies with antispyware software.
            </p>

            <p>
              {" "}
              The cookie information text on this site was derived from content
              provided by Attacat Internet Marketing http://www.attacat.co.uk/,
              a marketing agency based in Edinburgh. If you need similar
              information for your own website you can use their free cookie
              audit tool.
            </p>
          </Grid>

          {/* </Grid> */}
        </Container>
      </div>
      <Contact />

      <Footer />
    </div>
  );
};

export default Privacy;
