import {Grid, makeStyles, Box, Typography} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";
import Header from "./Header";
import ThankYouPageFooter from "./ThankYouPageFooter";

const useStyles = makeStyles(theme => ({
  thanks: {
    backgroundColor: "#D2E6E4",
    marginTop: "5%",
    marginBottom: "6%",
    borderRadius: "20px",
    marginLeft: "10%",
    marginRight: "10%",
    paddingTop: "2%",
    paddingBottom: "8%",
  },
  main: {
    backgroundColor: "#F8F8F8",
  },
  golo: {
    borderRadius: "50%",
    border: "6px solid #17664E",
    background: "#D2E6E4",
    height: "90px",
    width: "90px",
    color: "#17664E",
  },
  padding: {
    paddingTop: "1.8%",
  },
  alignContent: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding: "20px",
    },
  },
}));

const footerColor = "#17664E";
function ThankYou() {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Header elevation={4} padding={15} />
      <Grid className={classes.thanks}>
        <Grid container justifyContent="center">
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            className={classes.golo}>
            <Box fontWeight="bold">
              <img src="images/tick.svg" alt="" />
            </Box>
          </Grid>
        </Grid>
        {/* <Grid className={classes.padding} container justifyContent="center"><Typography><Box fontSize="15px" color="#4F4F4F"> Your data has been submitted successfully.</Box></Typography></Grid> */}
        <Grid className={classes.padding} container justifyContent="center">
          <Typography>
            <Box fontSize="15px" color="#4F4F4F">
              <p className={classes.alignContent}>
                Thank you, Please check your mailbox (including junk or spam) for
                a confirmation email.
              </p>
            </Box>
          </Typography>
        </Grid>
        <Grid className={classes.padding} container justifyContent="center">
          <Link to="/" style={{textDecoration: "none"}}>
            <Typography>
              <Box fontSize="15px" color="#17664E">
                Back to Homepage
              </Box>
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <ThankYouPageFooter color={footerColor} />
    </div>
  );
}

export default ThankYou;
