import {Button, CircularProgress} from "@material-ui/core";
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {makeStyles} from "@material-ui/styles";
import {toast} from "react-toastify";
import {useState} from "react";
const useStyles = makeStyles(theme => ({
  circularProgress: {
    width: "25px !important",
    height: "25px !important",
    marginLeft: "15px !important",
  },
  input: {
    padding: 15,
    fontSize: 20,
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: 5,
  },
  button: {
    "&:disabled": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "width": "100%",
    "padding": 10,
    "borderRadius": 10,
  },
  title: {
    textAlign: "center",
    fontSize: 14,
    padding: "10px 0",
    margin: 0,
    color: "#777",
    fontWeight: 500,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  powered: {
    color: "rgb(145, 145, 145)",
    fontSize: "12px",
    fontWeight: "500",
    textAlign: "center",
    margin: "0 10px 5px 0",
  },
  logos: {
    width: "40px",
    height: "24px",
    objectFit: "cover",
    marginRight: "5px",
  },
}));

const CheckoutForm = ({
  clientSecret,
  amount,
  formik,
  setPaypalToken,
  setAmount,
  setPaymentMethod,
  getPaymentStatus,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    formik?.setFieldValue("payment_method", "card");
    if (!stripe || !elements) {

      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      
      payment_method: {
        card: elements.getElement(CardElement),
        // billing_details: {
        //     name: 'Faruq Yusuff',
        // },
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      toast.error(result.error.message);
      console.log(result.error.message);
      setLoading(false);
    } else {

      toast.success("Payment Successful");
      getPaymentStatus(true)
      console.log("success", result);
      if (!formik) {
        setPaymentMethod("Bank");
        setPaypalToken(result.paymentIntent.id);
        setAmount(amount);
      }
      formik?.setFieldValue("paypal_token", result.paymentIntent.id);
      formik?.setFieldValue("paypal_amount", amount);
      setLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <hr />
      <div className={styles.title}>
        <div className={styles.powered}>Powered By</div>
        <div>
          <img
            className={styles.logos}
            src={require("../assets/stripe.png").default}
            alt="logo"
          />
        </div>
        <div>
          {" "}
          <img
            className={styles.logos}
            src={require("../assets/visa.png").default}
            alt="logo"
          />
        </div>
        <div>
          {" "}
          <img
            className={styles.logos}
            src={require("../assets/master-card.png").default}
            alt="logo"
          />
        </div>
        <div>
          {" "}
          <img
            className={styles.logos}
            src={require("../assets/express.png").default}
            alt="logo"
          />
        </div>
      </div>
      <CardElement className={styles.input} />
      <br />
      <Button
        type="submit"
        color="primary"
        variant="contained"
        className={styles.button}
        disabled={!stripe || !elements || loading}>
        Pay £{amount}
        {loading ? (
          <CircularProgress
            className={styles.circularProgress}
            color="secondary"
          />
        ) : (
          ""
        )}
      </Button>
    </form>
  );
};

export default CheckoutForm;
