import React, {useEffect, useState} from "react";
// import Header from "../components/Header";
import "./Blog.scss";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import axios from "axios";
import {Link} from "react-router-dom";

import DateRangeIcon from "@material-ui/icons/DateRange";
// import {useHistory} from "react-router-dom";
import {DOMAIN} from "../api";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Navbar from "./Navbar";
import MetaConfigure from "../components/helmet";

const Blog = () => {
  // const history = useHistory();
  const [blog, setBlog] = useState("");
  const createMarkup = descripton => ({__html: descripton});

  useEffect(() => {
    window.scrollTo(0, 0);

    axios.get(`${DOMAIN}/api/allblogs`).then(res => {
      setBlog(res.data);
    }, []);
  }, []);
  return (
    <>
      <MetaConfigure title="Blogs - Virtual Eye Consultant - Eye Health and Eye Care" />

      <Navbar />
      <div className="blog-section">
        {/* <Header /> */}
        <div className="blog-banner">
          <div className="container">
            <div className="breadcrumb-section">
              <h1 className="breadcrumb-title">Blogs</h1>
              <h5 className="breadcrumb-subtitle">
                <Link to={{pathname: "/"}}>Home</Link> / Blog
              </h5>
            </div>
          </div>
          <img src={require("../images/bg-blog.png").default} alt="banner" />
        </div>
        <div className="container">
          <div className="row">
            {blog?.blog?.map((item, index) => {
              return (
                <div className="col-md-3" key={index}>
                  <div className="section-card">
                    <Link
                      to={{
                        pathname: `/blogs/${item.slug}`,
                      }}>
                      <img src={`${DOMAIN}/uploads/${item.image}`} alt="img" />
                    </Link>

                    <div className="blog-content">
                      <div className="d-space-between">
                        <div className="d-flex">
                          <DateRangeIcon />

                          <p>{item?.date}</p>
                        </div>

                        <div className="d-flex">
                          <PersonOutlineIcon />
                          <p> {item?.author}</p>
                        </div>
                      </div>
                      <h2 className="title">
                        <Link
                          to={{
                            pathname: `/blogs/${item.slug}`,
                          }}>
                          {item?.title}
                        </Link>
                      </h2>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={createMarkup(
                          item.description || ""
                        )}></p>
                      {/* <p className="description">{item.description}</p> */}

                      <Link
                        to={{
                          pathname: `/blogs/${item.slug}`,
                        }}>
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
};
export default Blog;
