//uploadphoto

import {Grid, Typography, Box} from "@material-ui/core";
import React from "react";
import "./UploadPhoto.css";

import ImageBox from "./ImageBox";

function UploadPhoto() {
  return (
    <div className="uploadPhoto">
      <br />
      <Typography variant="h6" component="h5" align="center">
        <Box color="#4F4F4F">
          Please upload or take a photos of your eye problem or scans from the
          optician (3 required)
        </Box>
      </Typography>
      <br />
      <Grid container justifyContent="center" spacing={2}>
        {["file1", "file2", "file3"].map(name => (
          <ImageBox key={name} name={name} />
        ))}
      </Grid>
      <br /><br /><br />
      <div className="banner">
        <img src="/images/photoclick.png" alt="info-img" />
      </div>
    </div>
  );
}

export default UploadPhoto;
