import React from "react";
import {TextField} from "@material-ui/core";
import {useFormikContext} from "formik";

/**@type {React.FC<{
 * name: string,
 * type: string,
 * }>} */
const AdaptiveTextField = ({name, type, placeholder, pattern,value}) => {
  const formik = useFormikContext();
  if (type === "date") {
    return (
      <TextField
        variant="outlined"
        color="primary"
        size="small"
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={formik.isSubmitting}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        type="text"
        style={{width: "85%"}}
        placeholder={placeholder}
        pattern={pattern}
      />
    );
  }

  return (
    <TextField
      variant="outlined"
      color="primary"
      size="small"
      name={name}
      // value={ value ? value :formik.values[name]}
      value={ formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      disabled={formik.isSubmitting}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      type={type}
      style={{width: "85%"}}
      placeholder={placeholder}
      pattern={pattern}
      
    />
  );
};

export default AdaptiveTextField;
