import {Typography} from "@material-ui/core";

export const SampleImage = ({setOpenPopUp}) => {
  return (
    <div className="sample-image">
      <Typography> Detailed Examples of Accepted Photos</Typography>
      <img src="images/sampleEye1.jpg" alt="sample" />
      <button className="sample-btn" onClick={() => setOpenPopUp(false)}>
        OK
      </button>
    </div>
  );
};
