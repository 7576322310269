import {useFormikContext} from "formik";

const useDisableButton = activeStep => {
  const {
    values: {
      symptoms,
      eye,
      duration,
      file1,
      file2,
      file3,
      name,
      email,
      dob,
      contact,
      preferVideoConsultation,
      paypal_token,
      scheduleObject,
      payment_method,
      recaptcha,
      isInsured,
      postal_code,
      address,
      authorization_no,
      card_payment_status,
      membership_no,   availableDateSchema},
  } = useFormikContext();
  switch (activeStep) {
    case 0:
      if (symptoms.length === 0) {
        return {message: "Please specify your symptoms"};
      }
      if (eye === "") {
        return {message: "Please specify your eye"};
      }
      if (duration === "") {
        return {message: "Please specify your duration"};
      }
      return false;
    case 1:
      const files = [file1, file2, file3];
      let numberOfEmptyFiles = 0;
      files.forEach(file => {
        if (file === null) {
          numberOfEmptyFiles++;
        }
      });
      if (numberOfEmptyFiles !== 0) {
        if (numberOfEmptyFiles === 1) {
        return {message: `${numberOfEmptyFiles} image file missing`};
        } else {
        return {message: `${numberOfEmptyFiles} image files missing`};
        }
      }

      return false;
    case 2:
      if (name.length === 0) {
        return {message: "Please enter your Full Name."};
      }
      if (email.length === 0) {
        return {message: "Please enter your  Email Address."};
      }

      if (dob.length === 0) {
        return {message: "Please enter your Date of Birth."};
      }
      if (contact.length === 0) {
        return {message: "Please enter your Contact Number."};
      }
      if (preferVideoConsultation &&  scheduleObject === null) {  
        if(availableDateSchema !== null) {
         return {message: "Please select the consultation date"};
        }
     }
      if(!payment_method){
        return {message: "Please choose payment method "}
      }
      if (isInsured && postal_code.length === 0) {
        return {message: "Please enter your postal code."};

      }
      if (isInsured && address.length === 0) {
        return {message: "Please enter your address."};

      }if (isInsured && authorization_no.length === 0) {
        return {message: "Please enter your Authorization Number."};

      } if (isInsured && membership_no.length === 0) {
        return {message: "Please enter your Membership Number."};

      } 
      if (payment_method === "PayPal" && paypal_token === "") {
        return {message: "PayPal Pre-payment is required."};
      }

      if (payment_method === "card" &&  card_payment_status === "") {
        return {message: "Card Pre-payment is required."};

      }
    


      //  if (preferVideoConsultation &&  !scheduleObject){
      //     return {message: "Please select the consultation date"};

      //  }
//       if( preferVideoConsultation && availableDateSchema !== null){
//           if(!scheduleObject){
//           return {message: "Please select the consultation date"};

//           }
//           else {
//             return;
//           }

//       }else{
// return;
//       }

   

      if (recaptcha === "") {
        return {message: "Please verify you are not a robot"};
      }

      return false;

    default:
      return true;
  }
};

export default useDisableButton;
