import {Box, Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";
import {Link} from "react-router-dom";
import cricleGroup7 from "../images/circleGroup7.png";
const useStyles = makeStyles(theme => ({
  Price: {
    fontFamily: "'Open Sans', sans-serif",
    background: "#F5F5F7",
    paddingBottom: "64px",
  },
  title: {
    paddingTop: "30px",
  },
  text: {
    color: "#17664E",
    textAlign: "center",
    fontWeight: "700px",
  },
  we: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  no: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  golo: {
    borderRadius: "50%",
    background:
      "linear-gradient(140.86deg, rgba(210, 255, 255, 0.6) -5.51%, rgba(210, 255, 255, 0.2) 98.35%);",
    height: "95px",
    width: "95px",
    color: "#17664E",
  },
  golo1: {
    borderRadius: "50%",
    background:
      "linear-gradient(140.86deg, rgba(210, 230, 228, 0.4) -5.51%, rgba(210, 230, 228, 0.1) 98.35%)",
    height: "80px",
    width: "80px",
    color: "#17664E",
  },

  card: {
    fontFamily: "'Open Sans', sans-serif",
    borderRadius: "20px",
    background: "#FFFF",
    paddingTop: "20px",
    textAlign: "center",
    paddingBottom: "30px",
    margin: "30px",
  },
  paddings: {
    padding: "24px",
    paddingLeft: "29px",
  },
  cardColor1: {
    fontFamily: "'Open Sans', sans-serif",
    background: "#D2E6E4",
    borderRadius: "20px",
    paddingTop: "20px",
    textAlign: "center",
    paddingBottom: "30px",
    margin: 30,
  },
  priceValue: {
    fontFamily: "'Open Sans', sans-serif",
    width: "85px",
    textAlign: "center",
    marginTop: "10px",
    borderRadius: "50%",
    background:
      "linear-gradient(140.86deg, rgba(255, 255, 255, 0.6) -5.51%, rgba(255, 255, 255, 0.2) 98.35%)",
    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
  },
  // button: {
  //   fontFamily: "Livvic, sans-serif",
  //   fontWeight: "700",
  //   color: "#17664E",
  //   fontSize: "12px",
  //   border: "2px solid #17664E",
  //   [theme.breakpoints.down("md")]: {
  //     width: "130px",
  //   },
  // },
  cricleGroup7: {
    position: "absolute",
    // top: "1938px",
    marginTop: "-67px",
    right: "14%",
    [theme.breakpoints.down("md")]: {
      // top: "2350px",
      // right: "7%",
      display: "none",
    },
  },
  center: {
    background: "#F5F5F7",
    width: "100%",
    // display: 'flex',
    // justifyContent: "space-around",
    margin: "0 auto",
  },
}));
function Price({data}) {
  const classes = useStyles();
  if (!data) return <p>Loading</p>;
  return (
    <div className={classes.Price} id="price">
      <div className={classes.title}>
        <div className={classes.text}>
          <h1 className={classes.we}>{data.title}</h1>
          <h1 className={classes.no}>{data.subtitle}</h1>
        </div>
      </div>
      <Grid
        container
        className={classes.center}
        justifyContent="center"
        spacing={0}>
        {data.plans.map((item, index) => {
          return (
            <Grid
              key={index}
              container
              className={classes.cardcolor}
              xs={12}
              md={6}
              lg={3}
              item>
              <Grid
                container
                className={classes[index % 2 === 0 ? "cardColor1" : "card"]}
                alignItems="center"
                direction="column">
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignContent="center">
                  <Box fontWeight="bold" fontSize="40px">
                    {item.price}
                  </Box>
                </Grid>
                <Grid item className={classes.paddings}>
                  <Typography>{item.description}</Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignContent="center">
                  <Button
                    component={Link}
                    to={item.link.path}
                    className={classes.button}
                    color="primary">
                    {item.link.text}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <div className={classes.cricleGroup7}>
        <img src={cricleGroup7} alt="3rd" />
      </div>
    </div>
  );
}

export default Price;
