import {Box, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import React from "react";
// import {ReactComponent as FacebookIcon} from "../assets/facebook.svg";
// import {ReactComponent as InstagramIcon} from "../assets/instagram.svg";
// import {ReactComponent as TwitterIcon} from "../assets/twitter.svg";
// import {ReactComponent as YoutubeIcon} from "../assets/youtube.svg";
import {ReactComponent as FooterLeftCircles} from "../assets/footerLeftCircles.svg";
import ContactUs from "./ContactUs";

const useStyles = makeStyles(theme => {
  return {
    container: {
      backgroundColor: "rgba(23, 102, 78, 0.6)",
      padding: "10px 10px 20px 10px",
      // marginTop: "84px",
      color: "white",
      fontFamily: "'Open Sans', sans-serif",
      position: "relative",
      overflow: "hidden",
    },

    title: {
      fontSize: 40,
      fontWeight: 700,
      fontStyle: "normal",
      marginTop: 35,
      letterSpacing: 1,
    },
    addressTitle: {
      fontSize: 18,
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-between",
      },
    },
    order: {
      order: 1,
      [theme.breakpoints.down("xs")]: {
        order: -1,
      },
    },
    spacing: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    bgLgCircle: {
      width: "360px",
      height: "360px",
      position: "absolute",
      borderRadius: "50%",
      bottom: -200,
      right: "30%",
      background:
        " linear-gradient(119.9deg, rgba(255, 255, 255, 0.4) 5.36%, rgba(255, 255, 255, 0.1) 91.95%)",
      [theme.breakpoints.down("xs")]: {
        bottom: -50,
        left: "50%",
        transform: "translateX(-50%)",
      },
    },
    leftCircles: {
      position: "absolute",
      left: "79px",
      top: 50,
    },
    iconsmedia: {
      width: "calc(100% + 16px)",
      margin: "-8px",
      marginTop: "20px",

      [theme.breakpoints.down("xs")]: {
        position: "absolute",
        bottom: "110px",
        margin: "-5px 2px",
      },
    },

    footerSection: {
      [theme.breakpoints.down("xs")]: {
        minHeight: "220px",
      },
    },
    logoFootSection: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },
    address: {
      [theme.breakpoints.down("md")]: {
        transform: "translateX(0%)",
      },
    },

    addressGrid: {
      [theme.breakpoints.down("xs")]: {
        // transform: "translateX(0%)",
        marginLeft: "-15px",
      },
    },
    logoImage: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    logoFoot: {
      width: "90px",
      objectFit: "contain",
      zIndex: 1,
    },
    logoFootRight: {
      width: "90px",
      objectFit: "contain",
      marginLeft: "20px",
      zIndex: 1,
    },
    mdCircles: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    colorLink: {
      color: "white",
      textDecoration: "none",
      "&:hover": {
        color: "unset",
      },
    },
  };
});

const Contact = () => {
  const styles = useStyles();
  return (
    <div className={styles.container} id="contact">
      <FooterLeftCircles
        className={[styles.leftCircles, styles.mdCircles].join(" ")}
      />
      <div className={styles.bgLgCircle}></div>
      <Typography
        className={styles.title}
        style={{marginBottom: 20}}
        align="center"
        variant="h2"
        component="h2">
        <Box
          display={{
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          }}>
          CONTACT US
        </Box>
      </Typography>
      <Grid container>
        <Grid sm={7} xs={12} item container>
          <Grid md={3} className={styles.spacing} item></Grid>
          <Grid md={9} xs={12} item container direction="column" spacing={4}>
            {/* title */}
            <Grid item>
              <Typography className={styles.title} variant="h2" component="h2">
                <Box
                  display={{
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  }}>
                  CONTACT US
                </Box>
              </Typography>
            </Grid>

            {/* location details */}

            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              className={styles.footerSection}>
              {/* 2 boxes side by side */}

              <Grid
                xs={6}
                item
                direction="column"
                container
                spacing={1}
                className={styles.addressGrid}>
                <Grid item className={styles.addressTitle}>
                  Our Address
                </Grid>
                <Grid item>119 Harley Street</Grid>
                <Grid item>London W1G 6AU <br></br>United Kingdom</Grid>
              </Grid>
              {/* second box */}

              <Grid xs={6} item direction="column" container spacing={1}>
                <Grid item className={styles.addressTitle}>
                  Our Contacts
                </Grid>
                <Grid item>+44(0)20 300 54545</Grid>
                <Grid item>
                  <a
                    className={styles.colorLink}
                    href="mailto:info@virtualeye.net">
                    info@virtualeye.net
                  </a>
                </Grid>
              </Grid>
            </Grid>
            {/* social icons */}

            <Grid
              item
              container
              direction="row"
              className={styles.logoFootSection}>
              <Grid
                xs={6}
                sm={2}
                item
                direction="row"
                container
                spacing={1}
                className={styles.logoImage}>
                {/* <Grid item className={styles.addressTitle}> */}
                <img
                  src="/images/HIPAA.png"
                  alt="hipaa"
                  className={styles.logoFoot}
                />
                {/* </Grid> */}
              </Grid>
              <Grid
                xs={6}
                sm={2}
                item
                direction="row"
                container
                spacing={1}
                className={styles.addressGrid}>
                {/* <Grid item className={styles.addressTitle}> */}
                <img
                  src="/images/ICO.png"
                  alt="ico"
                  className={styles.logoFootRight}
                />
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          sm={5}
          xs={12}
          className={styles.order}
          justifyContent="center"
          spacing={2}>
          <Grid item>
            <ContactUs />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
