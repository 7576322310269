import React from "react";
import {Dialog} from "@material-ui/core";
import Privacy from "./Privacy";
import {SampleImage} from "./SampleImage";

export default function Popup(props) {
  const {openPopUp, setOpenPopUp, type} = props;
  return (
    <Dialog
      open={openPopUp}
      onClose={() => {
        setOpenPopUp(false);
      }}>
      {type === "stepper" ? (
        <SampleImage openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} />
      ) : (
        <Privacy openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} />
      )}
    </Dialog>
  );
}
