import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import cricleGroup5 from "../images/circleGroup5.png";
// import { Autoplay } from 'swiper';

import "./testimonial.scss";
import SwiperCore, {Autoplay, Pagination} from "swiper";
import {Grid, makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import {DOMAIN} from "../api";

SwiperCore.use([Autoplay, Pagination]);

const useStyle = makeStyles(theme => ({
  cricleGroup5: {
    position: "absolute",
    top: "0",
    [theme.breakpoints.down("md")]: {
      left: "-73px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  description: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
  },
  circle: {
    [theme.breakpoints.down("md")]: {
      height: "415px",
      width: "341px",
    },
  },
  imageHolder: {
    width: 56,
    height: 56,
    // backgroundColor: "#F5CCD9",
    color: "blue",
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    margin: "0px ",
  },
  smallCircle: {
    width: "100px",
    height: "100px",
    background:
      "linear-gradient(119.9deg, rgba(255, 255, 255, 0.4) 5.36%, rgba(255, 255, 255, 0.1) 91.95%)",
    borderRadius: "50%",
    position: "absolute",
    bottom: -150,
    right: "20%",
    [theme.breakpoints.down("xs")]: {
      bottom: 0,
    },
  },

  padding2: {
    padding: "20px",
  },

  circleo: {
    height: "400px",
  },
}));

export default function Testimonial({data}) {
  const classes = useStyle();
  const theme = useTheme();
  const matchesabovesm = useMediaQuery(theme.breakpoints.up("md"));
  if (!data) return <p>Loading</p>;
  return (
    <div className="testimonial">
      <div className={classes.smallCircle}></div>
      <h1 className={classes.padding2}>What our customers say</h1>
      <div className="content-block">
        <Swiper
          slidesPerView={matchesabovesm ? 2 : 1}
          spaceBetween={100}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 1000000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          className="mySwiper">
          {data?.map?.(
            ({name, description, photo_url, profession, title}, index) => {
              return (
                <SwiperSlide
                  style={{
                    display: "grid",
                    placeItems: "center",
                    paddingBottom: 10,
                  }}>
                  <div>
                    <div
                      className="content"
                      style={{
                        width: "80%",
                        margin: "0 auto",
                        cursor: "grab",
                        position: "relative",
                        minHeight: "200px",
                      }}>
                      <h1>{title}</h1>
                      <p
                        className={classes.description}
                        style={{lineHeight: "1.5rem"}}>
                        {description}
                      </p>
                    </div>
                    <div className="triangle"></div>
                  </div>
                  <Grid
                    container
                    spacing={2}
                    className="userDetail"
                    alignItems="center"
                    justifyContent="center"
                    marginTop="10px">
                    <Grid key={index}>
                      <figure
                        className={classes.imageHolder}
                        style={{
                          backgroundImage: `url(${DOMAIN + photo_url})`,
                        }}></figure>
                      {/* <img src={photo} alt="userImage" /> */}
                    </Grid>
                    <Grid item style={{marginLeft: 10}} className="userDetail">
                      <h5 style={{marginBottom: 8, lineHeight: 1.3}}>{name}</h5>
                      <p>{profession} </p>
                    </Grid>
                  </Grid>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </div>
      <div className={classes.cricleGroup5}>
        <img className={classes.circleo} src={cricleGroup5} alt="3rd" />
      </div>
    </div>
  );
}
