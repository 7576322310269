import {Form, Formik} from "formik";
import Navbar from "./Navbar";
import * as Yup from "yup";
import {getAcessInsuranceDetail, updateInsurance} from "../api";
import {useHistory} from "react-router";
import {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import { InsurerForm } from "./InsurerForm";

export const InsuranceDetail = () => {
  const [access, setAccess] = useState();
  const history = useHistory();
  var url = new URL(window.location.href);
  var path = url.pathname;
  var segments = path.split("/");
  var value = segments[segments.length - 1];
  const id = value;
  const token = url.searchParams.get('token');
  console.log("id",id);
  console.log(token,"token");


  useEffect(() => {
    if (value) {
      getAcessInsuranceDetail(value).then(res => {
        setAccess(res);
      });
    }
  }, [value]);

  if (access === false) {
    return <Redirect to="/thankyou" />;
  }
  return (
    <>
      <Navbar />

      <Formik
        initialValues={{
          postal_code: "",
          address: "",
          authorization_no: "",
          membership_no: "",
          recaptcha: "",
          insurance_company_id: "",
        }}
        validationSchema={Yup.object().shape({
          postal_code: Yup.string().required("Required"),
          address: Yup.string().required("Required"),
          authorization_no: Yup.string().required("Required"),
          membership_no: Yup.string().required("Required"),
          recaptcha: Yup.string().required("Please verify you are not a robot"),
          insurance_company_id: Yup.string().required("Required"),

        })}
        onSubmit={(values, {setSubmitting, resetForm}) => {
          const postData = new FormData();
          setSubmitting(true);

          postData.append("postal_code", values.postal_code);
          postData.append("address", values.address);
          postData.append("authorization_no", values.authorization_no);
          postData.append("membership_no", values.membership_no);
          postData.append("insurance_company_id",values.insurance_company_id);

          updateInsurance(postData, id,token).then(res => {
            if (res.status === 200) {
              resetForm();
              history.push("/thankyou");
            } else {
            }
            setSubmitting(false);
            console.log("done");
          });
        }}>
        {() => (
          <Form>
            <InsurerForm />
          </Form>
        )}
      </Formik>
    </>
  );
};
