import React, {useEffect, useState} from "react";
import "./SingleBlog.scss";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {useParams} from "react-router-dom";
import axios from "axios";
import {DOMAIN} from "../api";
import {Link} from "react-router-dom";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Navbar from "./Navbar";
import MetaConfigure from "../components/helmet";

const SingleBlog = () => {
  //   const history = useHistory();
  const {slug: blogId} = useParams();
  const [data, setData] = useState("");

  const createMarkup = descripton => ({__html: descripton});

  useEffect(() => {
    window.scrollTo(0, 0);

    if (blogId) {
      axios.get(`${DOMAIN}api/allblogs/${blogId}`).then(res => {
        setData(res.data);
      });
    }
  }, [blogId]);

  return (
    <>
    {data &&
    <div>
      <MetaConfigure title={data?.single_blog[0]?.title} />
      <Navbar />
      <div className="single-blog-section">
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="section-card">
                  <div className="breadcrumb-section">
                    <h5 className="breadcrumb-subtitle">
                      <Link to={{pathname: "/"}}>Home</Link> /{" "}
                      <Link to={{pathname: "/blogs"}}>Blog</Link> /{" "}
                      {data?.single_blog?.[0].title}
                    </h5>
                  </div>

                  <div className="blog-content">
                    <h1 className="main-title">
                      {data?.single_blog?.[0].title}
                    </h1>
                    <div className="date-section">
                      <div className="date-content">
                        <DateRangeIcon />
                        <p className="date-detail">
                          {data?.single_blog?.[0].date}
                        </p>
                      </div>
                      <div className="author-content">
                        <PersonOutlineIcon />
                        <p className="date-detail">
                          {data?.single_blog?.[0].author}
                        </p>
                      </div>
                    </div>
                    {/* <div className="d-space-between">
                    <div className="d-flex">
                      <DateRangeIcon />

                      <p>{data?.single_blog?.[0].date}</p>
                    </div>

                    <div className="d-flex">
                      <PersonOutlineIcon />
                      <p>{data?.single_blog?.[0].author}</p>
                    </div>
                  </div> */}
                    <img
                      src={`${DOMAIN}/uploads/${data?.single_blog?.[0].image}`}
                      alt="img"
                    />
                    <p
                      className="description"
                      dangerouslySetInnerHTML={createMarkup(
                        data?.single_blog?.[0].description || ""
                      )}></p>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="related-blog-section">
                  <h2 className="main-title">Related Blogs </h2>
                  <div className="title-border"></div>
                  {data?.others?.map((item, index) => {
                    return (
                      <div className="blog-content">
                        <div className="d-flex">
                          <Link to={`/blogs/${item.slug}`}>
                            <img
                              src={`${DOMAIN}/uploads/${item.image}`}
                              alt="img"
                            />
                          </Link>

                          <div>
                            <p className="author-name">{item?.author}</p>

                            <Link to={`/blogs/${item.slug}`}>
                              <h2 className="title">{item?.title}</h2>
                            </Link>

                            {/* <p className="description">{item?.description}</p> */}

                            {/* <Link
                            to={{
                              pathname: `/blogs/${item.slug}`,
                            }}>
                            Read more
                          </Link> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Contact />
        <Footer />
      </div>
    </div>}</>  
  );
};
export default SingleBlog;
