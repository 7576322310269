import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Main from "./Main";
import UploadPhoto from "./UploadPhoto";
import {Grid} from "@material-ui/core";
import Form from "./Form";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useDisableButton from "../hooks/useDisableButton";
import {useFormikContext} from "formik";
import Popup from "./PopUp";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },

  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorgrey: {
    backgroundColor: " #F8F8F8",
  },
  whole: {
    marginLeft: "8%",
    marginRight: "8%",
    marginBottom: "2%",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  buttonSpacing: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      marginBottom: "20px",
    },
  },
}));

function getSteps() {
  return ["Symptoms", "Upload Photo", "Personal Information"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <Main />;
    case 1:
      return <UploadPhoto />;
    case 2:
      return <Form />;
    default:
      return "Unknown stepIndex";
  }
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const disableButton = useDisableButton(activeStep);
  const history = useHistory();
  const [openPopUp, setOpenPopUp] = useState(false);

  const {
    handleSubmit,
    isSubmitting,
    values: {duration, eye, symptoms},
    // values: {paypal_token, duration, eye, symptoms},
  } = useFormikContext();

  const isLast = activeStep === steps.length - 1;
  const handleNext = () => {
    if (activeStep === 0) {
      if (symptoms.length > 0 && eye.length > 0 && duration.length > 0) {
        setOpenPopUp(true);
      }
    }
    if (disableButton) {
      toast.error(disableButton.message || "You cannot proceed");
      return;
    }
    if (isLast) { 
      handleSubmit();
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.push("/");
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <ToastContainer />
      <div className={classes.btnContent}>
        <div className={classes.root}>
          <Stepper elevation={1} activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className={classes.colorgrey}>
          {activeStep === steps.length ? (
            <div>
              <Typography>All steps completed</Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div style={{backgroundColor: " #F8F8F8"}}>
              <div className={classes.whole}>
                <Typography>{getStepContent(activeStep)}</Typography>
              </div>
              <Grid
                className="bckNxt"
                justifyContent="space-between"
                alignItems="center"
                container
                >
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-start">
                    {activeStep !== 0 && (
                      <Button
                        color="primary"
                        style={{textTransform: "none"}}
                        // disabled={(paypal_token, duration)}
                        onClick={handleBack}
                        className={classes.backButton}>
                        <Grid container style={{paddingRight: "15%"}}>
                          <ArrowBackIcon fontSize="small" />
                        </Grid>
                        <Grid>
                          <Typography>Previous</Typography>
                        </Grid>
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      style={{textTransform: "none"}}
                      color="primary"
                      disabled={isSubmitting}
                      onClick={handleNext}>
                      <Grid container style={{paddingRight: "15%"}}>
                        <Typography>{isLast ? "Submit" : "Next"}</Typography>
                      </Grid>
                      <Grid container>
                        <ArrowForwardIcon fontSize="small" />
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
      <Popup openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} type="stepper" />
    </div>
  );
}
