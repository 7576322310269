import React, {useEffect} from "react";
import {Container, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Navbar from "./Navbar";
import MetaConfigure from "../components/helmet";

const useStyles = makeStyles(theme => ({
  termSection: {
    marginTop: "80px",
  },
  Privacy: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "",
    textAlign: "left",
    padding: "0px 30px 30px 30px",
  },
  title: {
    fontSize: "25px",
    // color: "#17664E",
    lineHeight: "31.38px",
    fontWeight: "700",
    textAlign: "left",
    color: "#5E5E5E",
    marginTop: "30px",
  },
}));

const Terms = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaConfigure title="Terms - Virtual Eye Consultant - Eye health and Eye Care" />

      <Navbar />

      <div className={classes.termSection}>
        <Container>
          <Grid container direction="row" justifyContent="center">
            <Grid item className={classes.Privacy}>
              {/* <h1 className={classes.title}>Terms </h1> */}
              <h3 className={classes.title}>Problems</h3>
              <p>
                If there is a problem with any of the services provided by
                VirtualEye.net, please contact
              </p>
              a. info@virtualeye.net
              <p>
                {" "}
                b. London Eye Consultants, 119 Harley Street, London W1G 6AU
              </p>
              <h3 className={classes.title}>Privacy </h3>
              The data provided by the client is stored and secured according to
              HIPAA and ICO regulations and standards (Registration ZA170308).
              No information will be used for marketing or any commercial use.
              We have taken steps to be compliant with GDPR and the Data
              protection Act 2018
              <h3 className={classes.title}>Doctor qualifications:</h3>
              All specialists providing diagnosis, treatment, and management
              plans are fully registered with the United Kingdom General Medical
              Council and on the Ophthalmology Specialist Register. Each
              clinician holds an indemnity to practice with their preferred
              medical insurance provider. Individual responsibility lies with
              the clinician providing the report or consultation.
              <h3 className={classes.title}>Costs:</h3>
              Costs will be assessed before to enrollment. You can utilise your insurance card for registrations if you have one. Video consultation also available at your preferred time
              <h3 className={classes.title}>Prescriptions:</h3>
              Private prescriptions can be emailed or faxed if an address of a
              closest pharmacy accepting an email or fax can be provided by the
              client. There is no extra fee for the client for prescriptions but
              all pharmacy and drug costs will be the responsibility of the
              client
              <h3 className={classes.title}>Follow up virtual consultation:</h3>
              We advise that patients keep their own records including photos,
              scans, reports so follow up consultation can be conducted by
              providing this data on request by the doctor.
            </Grid>
          </Grid>
        </Container>
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default Terms;
