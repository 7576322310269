/**
 *
 * @param {string} value
 * @returns
 */
export function validateDDMMYYYY(value) {
  let dateRegex =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  return dateRegex.test(value);
}

/**
 * Return days between two dates in positive integer
 * @param {string} date1 DD/MM/YYYY
 * @param {string} date2 DD/MM/YYYY
 * @returns
 */

export const getDaysBetween = (date1 = "", date2 = "") => {
  //dates will be in DD/MM/YYYY format
  //convert these to YYYY-MM-DD
  const dateString1 = date1.split("/").reverse().join("-");
  const dateString2 = date2.split("/").reverse().join("-");
  //create date objects
  const date1Obj = new Date(dateString1);
  const date2Obj = new Date(dateString2);
  //calculate difference in milliseconds in positive integer
  const diff = date2Obj.getTime() - date1Obj.getTime();
  //convert to days
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  return diffDays;
};

/**
 *
 * @param {string} DDMMYYYY
 */
export const isMoreThanOneMonth = DDMMYYYY => {
  //get current date
  const today = new Date();
  //convert it to DD/MM/YYYY
  const todayString = `${today.getDate()}/${
    today.getMonth() + 1
  }/${today.getFullYear()}`;
  //get days between today and date
  const daysBetween = getDaysBetween(DDMMYYYY, todayString);
  //if days between is more than 30 return true
  return daysBetween > 30;
};
