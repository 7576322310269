const GenerateFormData = values => {
  const formData = new FormData();
  formData.append("email", values.email);
  formData.append("name", values.name);
  formData.append("eye", values.eye);
  formData.append("symptoms", values.symptoms);
  formData.append("time", values.duration);
  formData.append("nonListed", values.description);
  formData.append("videoConsultation", values.scheduleObject ? values.preferVideoConsultation : false);
  formData.append("contact", values.contact);
  formData.append("DOB", values.dob);
  formData.append("is_video_consultation", values.preferVideoConsultation);
  formData.append("payment_method", values.payment_method);

  if (values.preferVideoConsultation && values.availableDateSchema !== null) {
    formData.append("paypal_amount", values.paypal_amount);
    formData.append("paypal_token", values.paypal_token);
    formData.append("schedule_token", values.scheduleObject.id);
    formData.append("payment_method", values.payment_method);
  }

  if (values.payment_method === "insured") {
    formData.append("postal_code", values.postal_code);
    formData.append("address", values.address);
    formData.append("authorization_no", values.authorization_no);
    formData.append("membership_no", values.membership_no);
    formData.append("insurance_company_id",values.insurance_company_id);

  }

      if(values.payment_method === "PayPal"){
        formData.append("paypal_amount", values.paypal_amount);
    formData.append("paypal_token", values.paypal_token);


      }

  values.files.forEach((report, index) => {
    formData.append(`report[${index}]`, report);
  });
  [values.file1, values.file2, values.file3].forEach((image, index) => {
    formData.append(`image[${index}]`, image);
  });
  return formData;
};

export default GenerateFormData;
