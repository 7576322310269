import React from "react";
import {Helmet} from "react-helmet";

class Application extends React.Component {
  render() {
    return (
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.title}</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
          {/* <link rel="apple-touch-icon" href="./images/logo.png" /> */}
        </Helmet>
      </div>
    );
  }
}

export default Application;
