import React from "react";
import Navbar from "../pages/Navbar";
import {makeStyles} from "@material-ui/styles";
import Virtual from "../components/Virtual.js";
import About from "../components/About";
import WhyChoose from "../components/WhyChoose";
import Price from "../components/Price";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Testimonial from "../components/Testimonial";
import {getHomepageContent, mockedPlans} from "../api";
import {useQuery} from "react-query";
import {toast, ToastContainer} from "react-toastify";
import MetaConfigure from "../components/helmet";

const useStyles = makeStyles(theme => ({
  home: {
    background: "#F5F5F7",
  },
}));

const Home = () => {
  const { data} = useQuery("dispayContent", getHomepageContent, {
    onError: err => {
      toast.error(err.message || "An error occurred while fetching content");
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.home}>
      <MetaConfigure title="Home - Virtual Eye Consultant - Eye Health and Eye Care" />

      <ToastContainer />
      <Navbar />
      <Virtual />
      {/* {loading ? (
        "Loading"
      ) : data ? (
        <> */}
          <About type={data?.about_us[0].welcome} />
          <WhyChoose data={data?.about_us[0].why_choose_us} />
          {/* <Price data={mockedPlans} /> */}
          <Testimonial data={data?.feedback} />
        {/* </>
      ) : null} */}
          <Price data={mockedPlans} />

      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
