export const detectCameraPresence = () =>
  new Promise((resolve, reject) => {
    navigator.getMedia =
      navigator.getUserMedia || // use the proper vendor prefix
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    navigator.getMedia(
      {video: true},
      function () {
        // webcam is available
        resolve(true);
      },
      function () {
        // webcam is not available
        reject(false);
      }
    );
  });
