import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(theme => ({
  Privacy: {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: "",
    textAlign: "center",
    padding: "0px 30px 30px 30px",
  },
  title: {
    fontSize: "25px",
    color: "#17664E",
    lineHeight: "31.38px",
    fontWeight: "700",
  },
  cancel: {
    float: "right",
    padding: "10px",
  },
  br: {
    borderRadius: 10,
  },
}));
function Privacy({ setOpenPopUp }) {
  const classes = useStyles();
  return (
    <div className={classes.br}>
      <div className={classes.cancel}>
        <ClearIcon
          style={{ cursor: "pointer" }}
          onClick={() => setOpenPopUp(false)}
        />
      </div>
      <Grid container direction="row" justifyContent="center">
        <Grid item className={classes.Privacy}>
          <h1 className={classes.title}>Privacy</h1>
          <p>
            With a few finger swipes on your phone or clicks on your laptop, we
            can provide a diagnosis, a report and a management plan for your eye
            condition. With Virtual Eye Consultant, we can save you time, effort
            and money. You will receive expert ophthalmologist opinions without
            the risk of encountering an inexperienced doctor at a hospital, and
            we aim to generate the results within 24 hours after receiving your
            data.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Privacy;
