import React from "react";
import Header from "../components/Header";
import {Formik} from "formik";
import Stepper from "../components/Stepper";
import * as Yup from "yup";
import {useHistory} from "react-router";
import {postData} from "../api";
import GenerateFormData from "../hooks/GenerateFormData";
import {toast} from "react-toastify";
import {validateDDMMYYYY} from "../utils/date";
import MetaConfigure from "../components/helmet";

function Consult() {
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        symptoms: [],
        eye: "",
        duration: "",
        description: "",
        files: [],
        file1: null,
        file2: null,
        file3: null,
        name: "",
        email: "",
        dob: "",
        contact: "",
        preferVideoConsultation: false,
        payment_method: "",
        postal_code:"",
        address:"",
        authorization_no:"",
        membership_no:"",
        consultDate: "",
        paypal_token: "",
        paypal_amount: "",
        scheduleObject: null,
        availableDateSchema: null,
        insurance_company_id:"",
        card_payment_status:"",
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(3, "Must be at least 3 characters ")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        dob: Yup.string()
          .required("Required")
          .test("dob", "Invalid date format DD/MM/YYYY", (value = "") =>
            validateDDMMYYYY(value)
          ),
        contact: Yup.string()
          .min(10, "Minimum 10 Number")
          .max(15, "Maximum 15 Number")
          .required("Required"),
          
          payment_method:Yup.string().required("Required"),

          postal_code: Yup.string().when('payment_method', {
            is: 'insured',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),

          insurance_company_id: Yup.string().when('payment_method', {
            is: 'insured',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),

          address: Yup.string().when('payment_method', {
            is: 'insured',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),

          authorization_no: Yup.string().when('payment_method', {
            is: 'insured',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),
        

          membership_no: Yup.string().when('payment_method', {
            is: 'insured',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().notRequired(),
          }),

      
       
      })}
      onSubmit={(values, {setSubmitting}) => {
        const formdata = GenerateFormData(values);
        setSubmitting(true);
        postData(formdata)
          .then(res => {  
            setSubmitting(false);
            console.log(res);
            history.push("/thankyou");
          })
          .catch(err => {
            setSubmitting(false);
            console.error(err);
            toast.error(err.message || "Error posting data to the server");
          });
      }}>
      <div>
        <MetaConfigure title="Consult Now - Virtual Eye Consultant - Eye Health and Eye Care" />
        <Header />
        <Stepper />
      </div>
    </Formik>
  );
}

export default Consult;
