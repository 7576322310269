import axios from "axios";
import {getCurrentHost} from "../utils/current-host";

let serverUrl = "https://qa.admin.virtualeye.net/";

const currentHost = getCurrentHost();

if (currentHost === "uat") {
  serverUrl = "https://admin.virtualeye.net/";
}
if (currentHost === "live") {
  serverUrl = "https://admin.virtualeye.net/";
}
export const DOMAIN = serverUrl;

export const getConsultationDate = async () => {
  const response = await axios.get(DOMAIN + "api/consultation_date");
  return response.data;
};

export const getPricing = async (doctorId = 1) => {
  const response = await axios.get(DOMAIN + "api/pricing/" + doctorId);
  return response.data;
};

export const customerDetail = async () => {
  const response = await axios.get(
    DOMAIN + "customers_detail?customer_detail=1 "
  );
  return response.data;
};

export const verifyReschedule = async token => {
  const response = await axios.get(DOMAIN + `verify_reschedule/${token}`);
  return response;
};

export const getReportPrice = async () => {
  const response = await axios.get(DOMAIN + "api/report_price");
  return response.data;
};

export const insuranceCompanyDetails = async () => {
  const response = await axios.get(DOMAIN + "api/insuranceCompanyDetails");
  return response.data;
};
export const postData = data => {
  return axios.post(DOMAIN + "questioniare", data);
};

export const postEmailData = data => {
  return axios.get(DOMAIN + `api/insurance_detail?email=${data}`);
};
export const updateInsurance = (data, id, token) => {
  return axios.post(
    DOMAIN + `update_insurance_info/${id}?token=${token}`,
    data
  );
  // return axios.post(`http://be08-2400-1a00-b060-9fca-d059-779d-8ef5-d2d2.ngrok-free.app/update_insurance_info/${id}`, data);
};

export const updateRescheduleDate = (slug, doctorId) => {
  return axios.post(DOMAIN + `reschedule/${slug}/${doctorId}`);
};
export const sendMessage = data => {
  return axios.post(DOMAIN + "api/contact_us", data);
};

export const getHomepageContent = async () => {
  const {data} = await axios.get(DOMAIN + "api/feedbacks");
  return data;
};

export const getWhyChooseUsPoints = async () => {
  const {data} = await axios.get(DOMAIN + "api/why_choose_us");
  return data;
};

export const getAcessInsuranceDetail = async id => {
  const {data} = await axios.get(DOMAIN + `recheck_insurance_rejection/${id}`);
  return data;
};
export const mockedPlans = {
  title: "Highest Quality At An Affordable Price",
  subtitle: "",
  plans: [
    {
      price: "£0",
      description:
        "If our SPECIALIST can not generate a diagnosis or recommendation from the information you've provided",
      // "if our SPECIALIST can not generate a diagnosis or recommendation from the information you've provided",
      link: {
        // text: "Consult now",
        path: "/consult",
      },
    },
    {
      price: "£89",
      description:
        "If the information you provide generates a complete diagnosis & management plan",
      link: {
        // text: "Consult now",
        path: "/consult",
      },
    },
    {
      price: "£150",
      description: "If a VIRTUAL VIDEO  consultation is preferred ",
      // "if our SPECIALIST can not generate a diagnosis or recommendation from the information you've provided",
      link: {
        // text: "Consult now",
        path: "/consult",
      },
    },
  ],
};

export const getPaymentMetaData = id => {
  return axios.get(DOMAIN + "/payment/" + id);
};

export const postPayment = ({
  user_token,
  paypal_token,
  amount,
  paymentMethod,
}) => {
  return axios.post(
    `${DOMAIN}payment/${user_token}/${paypal_token}/${amount}`,
    {
      payment_method: paymentMethod,
    }
  );
};

export const getVirtualeyeImage = async () => {
  const {data} = await axios.get(DOMAIN + "api/sliders");
  return data;
};

export const getPolicy = async () => {
  const {data} = await axios.get(DOMAIN + "api/sliders");
  return data;
};

export const getThankyou = async () => {
  const {data} = await axios.get(DOMAIN + "about_api");
  return data;
};

export const getClientSecretKey = async doctorId => {
  const {data} = await axios.post(DOMAIN + "payment_intent", {
    amount: doctorId,
  });

  return data;
};
